import { paths } from 'src/routes/paths';

import axios from 'src/utils/axios';

// ----------------------------------------------------------------------
export const jwtDecode = (accessToken) => {
  const base64Url = accessToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const CenterName = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return '';
  }
  const decoded = jwtDecode(accessToken);
  return decoded.center_name;
};

export const UserName = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return '';
  }
  const decoded = jwtDecode(accessToken);
  return decoded.name;
};

export const InstallationName = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return '';
  }
  const decoded = jwtDecode(accessToken);
  return decoded.installation_name;
};
export const CenterID = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return '';
  }
  const decoded = jwtDecode(accessToken);
  return decoded.center_login;
};

export const InstallationID = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return 0;
  }
  const decoded = jwtDecode(accessToken);
  return Number(decoded.installation);
};

export const DistributorProfile = () => {
  const accessToken = sessionStorage.getItem('accessToken');
  if (!accessToken) {
    return 0;
  }
  const decoded = jwtDecode(accessToken);
  return Number(decoded.distributor_profile);
};
// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------
export const setForcrSession = (accessToken) => {
  sessionStorage.removeItem('accessToken');
  sessionStorage.setItem('accessToken', accessToken);
  delete axios.defaults.headers.common.Authorization;
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const ExitSession = (accessToken) => {
  sessionStorage.removeItem('accessToken');
  delete axios.defaults.headers.common.Authorization;
};

export const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};
